<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
const props = withDefaults(
  defineProps<{
    title?: string;
    totalItems: number;
    scrollArrowSize?: number;
    scrollArrowSpacing?: number;
  }>(),
  {
    title: "",
    scrollArrowSize: 40,
    scrollArrowSpacing: 50,
  },
);

// -----------------------
// reactive properties
// -----------------------
const scrollIndex = ref(0);
const baseWidth = ref(0);
const parentElement = ref(null);

// -----------------------
// computed properties
// -----------------------
const canScroll = computed(() => {
  return props.totalItems > visibleItemsNumber.value;
});

const canScrollLeft = computed(() => {
  return scrollIndex.value > 0;
});

const visibleItemsNumber = computed(() => {
  if (baseWidth.value > 1440) {
    return 5;
  } else if (baseWidth.value > 1280) {
    return 4;
  } else if (baseWidth.value > 1024) {
    return 3;
  } else {
    return 2;
  }
});

const canScrollRight = computed(() => {
  return scrollIndex.value < props.totalItems - visibleItemsNumber.value;
});

const scrollValue = computed(() => {
  if (baseWidth.value > 1440) {
    return 20.8;
  } else if (baseWidth.value > 1280) {
    return 26;
  } else if (baseWidth.value > 1024) {
    return 34.666;
  } else {
    return 52;
  }
});

const childrenStyle = computed(() => {
  if (baseWidth.value > 1440) {
    return "flex: 0 0 16.8%;";
  } else if (baseWidth.value > 1280) {
    return "flex: 0 0 22%;";
  } else if (baseWidth.value > 1024) {
    return "flex: 0 0 30.666%;";
  } else {
    return "flex: 0 0 48%;";
  }
});

const buttonStyle = computed(() => {
  return {
    width: `${props.scrollArrowSize}px`,
    height: `${props.scrollArrowSize}px`,
    "font-size": `${props.scrollArrowSize}px`,
  };
});

const leftButtonStyle = computed(() => {
  return {
    ...buttonStyle.value,
    "margin-right": `${props.scrollArrowSpacing}px`,
  };
});

const rightButtonStyle = computed(() => {
  return {
    ...buttonStyle.value,
    "margin-left": `${props.scrollArrowSpacing}px`,
  };
});

// -----------------------
// helper methods
// -----------------------
const scrollLeft = () => {
  if (canScrollLeft.value) {
    scrollIndex.value--;
  }
};

const scrollRight = () => {
  if (canScrollRight.value) {
    scrollIndex.value++;
  }
};

const updateBaseWidth = () => {
  if (parentElement.value !== null) {
    baseWidth.value = parentElement.value.clientWidth;
  }
};

// -----------------------
// vue events
// -----------------------
onMounted(updateBaseWidth);
onUpdated(updateBaseWidth);
window.addEventListener("resize", updateBaseWidth);
</script>

<template>
  <div>
    <h2
      v-if="title && title.length > 0"
      class="block font-lora font-bold !text-30 text-center lg:mb-[50px]"
    >
      {{ title }}
    </h2>
    <div ref="parentElement" class="flex flex-col md:flex-row items-center">
      <!-- Desktop button -->
      <button
        v-if="canScroll"
        :aria-label="$t('previous')"
        class="hidden md:block"
        :style="leftButtonStyle"
        :disabled="!canScrollLeft"
        :class="{ 'opacity-30': !canScrollLeft }"
        @click="scrollLeft"
      >
        <i class="m-icon-arrow_left"></i>
      </button>

      <div
        v-touch:swipe.left="scrollRight"
        v-touch:swipe.right="scrollLeft"
        class="w-full pl-[6px] pr-[6px] pb-[6px] flex justify-start items-stretch overflow-hidden"
      >
        <div
          :style="`margin-left: -${scrollIndex * scrollValue}%`"
          class="transition-all duration-500"
        ></div>
        <slot name="default" :items-style="childrenStyle"></slot>
      </div>

      <!-- Desktop button -->
      <button
        v-if="canScroll"
        :aria-label="$t('next')"
        class="hidden md:block"
        :disabled="!canScrollRight"
        :style="rightButtonStyle"
        :class="{ 'opacity-30': !canScrollRight }"
        @click="scrollRight"
      >
        <i class="m-icon-arrow_right"></i>
      </button>

      <!-- Mobile buttons -->
      <div
        class="flex md:hidden justify-between w-full"
        :style="{ padding: `20px ${scrollArrowSpacing}px 12px` }"
      >
        <button
          v-if="canScroll"
          :aria-label="$t('previous')"
          :style="leftButtonStyle"
          :disabled="!canScrollLeft"
          :class="{ 'opacity-30': !canScrollLeft }"
          @click="scrollLeft"
        >
          <i class="m-icon-arrow_left"></i>
        </button>
        <button
          v-if="canScroll"
          :aria-label="$t('next')"
          :disabled="!canScrollRight"
          :style="rightButtonStyle"
          :class="{ 'opacity-30': !canScrollRight }"
          @click="scrollRight"
        >
          <i class="m-icon-arrow_right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
