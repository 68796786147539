<script lang="ts" setup>
import { useMainStore } from "~/stores/main";
import type { BackgroundImage } from "~/types/common";

interface TilesConfiguration {
  leftType?: "image" | "text" | "video";
  leftBackgroundImage?: BackgroundImage;
  leftTitle?: string;
  leftText?: string;
  leftLinkText?: string;
  leftLinkUrl?: string;
  leftButtonText?: string;
  leftButtonUrl?: string;
  leftVideoUrl?: string;
  leftVideoId?: string;
  rightType?: "image" | "text" | "video";
  rightBackgroundImage?: BackgroundImage;
  rightTitle?: string;
  rightText?: string;
  rightLinkText?: string;
  rightLinkUrl?: string;
  rightButtonText?: string;
  rightButtonUrl?: string;
  rightVideoUrl?: string;
  rightVideoId?: string;
}

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  options: TilesConfiguration;
}>();

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const { getAssetUrl } = useHelpers();

// -----------------------
// reactive properties
// -----------------------
const container = ref();
const containerWidth = ref<number>(0);
const containerHeight = ref<number>(0);
const bodyWidth = ref<number>(0);
const baseWidth = ref<number>(1920);
const mobileBaseWidth = ref<number>(390);
const baseHeight = ref<number>(500);
const mobileBaseHeight = ref<number>(617);

// -----------------------
// computed properties
// -----------------------
const containerStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    if (
      props.options.leftType === "image" &&
      props.options.rightType === "image"
    ) {
      return {
        gap: `${getRelativeSize(12)}px`,
      };
    } else {
      return {
        gap: `${getRelativeSize(28)}px`,
      };
    }
  } else {
    return {
      aspectRatio:
        props.options.leftType === "image" ||
        props.options.rightType === "image"
          ? "1440 / 500"
          : null,
      gap: `${getRelativeSize(14)}px`,
    };
  }
});

const leftTileStyle = computed(() => {
  const style: Record<string, string> = {};

  if (props.options.leftType === "video") {
    style.margin = "0 16px";
  } else if (props.options.leftType === "image") {
    const backgroundImage = props.options.leftBackgroundImage;

    if (mainStore.breakpoint === "sm") {
      style.margin = "0 16px";
    }

    if (mainStore.breakpoint === "sm" && backgroundImage?.width) {
      style["aspect-ratio"] =
        `${backgroundImage.width} / ${backgroundImage.height}`;
    }

    style["background-image"] =
      `url('${getAssetUrl(props.options.leftBackgroundImage?.url)}')`;
  }

  return style;
});

const rightTileStyle = computed(() => {
  const style: Record<string, string> = {};

  if (props.options.rightType === "video") {
    style.margin = "0 16px";
  } else if (props.options.rightType === "image") {
    const backgroundImage = props.options.rightBackgroundImage;

    if (mainStore.breakpoint === "sm") {
      style.margin = "0 16px";
    }

    if (mainStore.breakpoint === "sm" && backgroundImage?.width) {
      style["aspect-ratio"] =
        `${backgroundImage.width} / ${backgroundImage.height}`;
    }

    style["background-image"] =
      `url('${getAssetUrl(props.options.rightBackgroundImage?.url)}')`;
  }

  return style;
});

const leftTileContentStyle = computed(() => {
  if (props.options.leftType === "image") {
    return {
      padding: `${getRelativeSize(10)}px ${getRelativeSize(16)}px`,
      "border-top-right-radius": `${getRelativeSize(8)}px`,
      "border-bottom-right-radius": `${getRelativeSize(8)}px`,
      top: `${getRelativeSize(20)}px`,
    };
  } else {
    return {
      padding: `0 ${getRelativeSize(40)}px`,
    };
  }
});

const rightTileContentStyle = computed(() => {
  if (props.options.rightType === "image") {
    return {
      padding: `${getRelativeSize(10)}px ${getRelativeSize(16)}px`,
      "border-top-right-radius": `${getRelativeSize(8)}px`,
      "border-bottom-right-radius": `${getRelativeSize(8)}px`,
      bottom: `${getRelativeSize(20)}px`,
    };
  } else {
    return {
      padding: `0 ${getRelativeSize(40)}px`,
    };
  }
});

const leftTitleStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    if (props.options.leftType === "image") {
      return {
        "font-size": `${getRelativeSize(24)}px`,
        "line-height": `${getRelativeSize(32)}px`,
      };
    } else {
      return {
        "font-size": `${getRelativeSize(24)}px`,
        "line-height": `${getRelativeSize(32)}px`,
      };
    }
  } else if (props.options.leftType === "image") {
    return {
      "font-size": `${getRelativeSize(30)}px`,
      "line-height": `${getRelativeSize(36)}px`,
    };
  } else {
    return {
      "font-size": `${getRelativeSize(36)}px`,
      "line-height": `${getRelativeSize(40)}px`,
    };
  }
});

const rightTitleStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    if (props.options.rightType === "image") {
      return {
        "font-size": `${getRelativeSize(24)}px`,
        "line-height": `${getRelativeSize(32)}px`,
      };
    } else {
      return {
        "font-size": `${getRelativeSize(24)}px`,
        "line-height": `${getRelativeSize(32)}px`,
      };
    }
  } else if (props.options.rightType === "image") {
    return {
      "font-size": `${getRelativeSize(30)}px`,
      "line-height": `${getRelativeSize(36)}px`,
    };
  } else {
    return {
      "font-size": `${getRelativeSize(36)}px`,
      "line-height": `${getRelativeSize(40)}px`,
    };
  }
});

const textStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    return {
      "margin-top": `${getRelativeSize(10)}px`,
      "font-size": `${getRelativeSize(16)}px`,
      "line-height": `${getRelativeSize(24)}px`,
    };
  } else {
    return {
      "margin-top": `${getRelativeSize(32)}px`,
      "font-size": `${getRelativeSize(20)}px`,
      "line-height": `${getRelativeSize(28)}px`,
    };
  }
});

const linkStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    return {
      "font-size": `${getRelativeSize(14)}px`,
      "line-height": `${getRelativeSize(20)}px`,
    };
  } else {
    return {
      "font-size": `${getRelativeSize(20)}px`,
      "line-height": `${getRelativeSize(28)}px`,
    };
  }
});

const buttonStyle = computed(() => {
  if (mainStore.breakpoint === "sm") {
    return {
      "font-size": `${getRelativeSize(16, "bodyWidth")}px`,
      padding: `${getRelativeSize(5, "bodyWidth")}px ${getRelativeSize(30, "bodyWidth")}px`,
      "margin-top": `${getRelativeSize(32, "bodyWidth")}px`,
    };
  } else {
    return {
      "font-size": `${getRelativeSize(16, "bodyWidth")}px`,
      padding: `${getRelativeSize(10, "bodyWidth")}px ${getRelativeSize(60, "bodyWidth")}px`,
      "margin-top": `${getRelativeSize(32, "bodyWidth")}px`,
    };
  }
});

const leftContentVisible = computed(() => {
  if (props.options.leftType === "video") {
    // No content for videos
    return false;
  } else if (props.options.leftType === "image") {
    return (
      props.options.leftTitle &&
      props.options.leftLinkText &&
      props.options.leftLinkUrl
    );
  } else {
    return true;
  }
});

const rightContentVisible = computed(() => {
  if (props.options.rightType === "video") {
    // No content for videos
    return false;
  } else if (props.options.rightType === "image") {
    return (
      props.options.rightTitle &&
      props.options.rightLinkText &&
      props.options.rightLinkUrl
    );
  } else {
    return true;
  }
});

// -----------------------
// helper methods
// -----------------------
const getRelativeSize = (
  basis: number,
  basisType:
    | "containerWidth"
    | "containerHeight"
    | "bodyWidth" = "containerWidth",
) => {
  let left, right;

  if (basisType === "containerWidth") {
    left = containerWidth.value;
  } else if (basisType === "containerHeight") {
    left = containerHeight.value;
  } else {
    left = bodyWidth.value;
  }

  if (mainStore.breakpoint === "sm") {
    right =
      basisType === "containerHeight"
        ? mobileBaseHeight.value
        : mobileBaseWidth.value;
  } else {
    right =
      basisType === "containerHeight" ? baseHeight.value : baseWidth.value;
  }

  return Math.round(left * (basis / right));
};

const updateBaseSizes = () => {
  bodyWidth.value = document.body.clientWidth;

  if (container.value) {
    const clientRect = container.value.getBoundingClientRect();

    containerWidth.value = clientRect.width;
    containerHeight.value = clientRect.height;
  }
};

onMounted(() => {
  window.addEventListener("resize", updateBaseSizes);
  updateBaseSizes();
});
</script>

<template>
  <div
    ref="container"
    class="tiles-container text-white grid grid-cols-1 md:grid-cols-2"
    :style="containerStyle"
  >
    <!-- Left tile -->
    <div
      class="left-tile relative bg-no-repeat bg-cover bg-center"
      :class="{
        [`${options.leftType}-tile`]: true,
        'bg-white': options.leftType === 'text',
      }"
      :style="leftTileStyle"
    >
      <iframe
        v-if="options.leftType === 'video' && options.leftVideoId"
        class="w-full aspect-video"
        :src="'//www.youtube.com/embed/' + options.leftVideoId"
        title="Youtube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div
        v-if="leftContentVisible"
        class="content"
        :class="{
          'absolute bg-brand-primary/40': options.leftType === 'image',
          'text-black md:h-full flex flex-col justify-center':
            options.leftType === 'text',
        }"
        :style="leftTileContentStyle"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="title font-bold"
          :style="leftTitleStyle"
          v-html="options.leftTitle"
        ></div>
        <!-- eslint-enable -->
        <div
          v-if="
            options.leftType === 'image' &&
            options.leftLinkText &&
            options.leftLinkUrl
          "
          class="link text-right"
        >
          <a
            class="!text-white"
            :style="linkStyle"
            :href="options.leftLinkUrl"
            >{{ options.leftLinkText }}</a
          >
        </div>
        <div v-if="options.leftType === 'text'" :style="textStyle">
          {{ options.leftText }}
        </div>
        <a
          v-if="
            options.leftType === 'text' &&
            options.leftButtonText &&
            options.leftButtonUrl
          "
          :href="options.leftButtonUrl"
          class="self-center md:self-end bg-brand-primary !text-white rounded-30 font-bold"
          :style="buttonStyle"
          >{{ options.leftButtonText }}</a
        >
      </div>
    </div>

    <!-- Right tile -->
    <div
      class="right-tile relative bg-no-repeat bg-cover bg-center"
      :class="{
        [`${options.rightType}-tile`]: true,
        'bg-white': options.rightType === 'text',
      }"
      :style="rightTileStyle"
    >
      <iframe
        v-if="options.rightType === 'video' && options.rightVideoId"
        class="w-full aspect-video"
        :src="'//www.youtube.com/embed/' + options.rightVideoId"
        title="Youtube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div
        v-if="rightContentVisible"
        class="content"
        :class="{
          'absolute bg-brand-primary/40': options.rightType === 'image',
          'text-black md:h-full flex flex-col justify-center':
            options.rightType === 'text',
        }"
        :style="rightTileContentStyle"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="title font-bold"
          :style="rightTitleStyle"
          v-html="options.rightTitle"
        ></div>
        <!-- eslint-enable -->
        <div
          v-if="
            options.rightType === 'image' &&
            options.rightLinkText &&
            options.rightLinkUrl
          "
          class="link text-right"
        >
          <a
            class="!text-white"
            :style="linkStyle"
            :href="options.rightLinkUrl"
            >{{ options.rightLinkText }}</a
          >
        </div>
        <div v-if="options.rightType === 'text'" :style="textStyle">
          {{ options.rightText }}
        </div>
        <a
          v-if="
            options.rightType === 'text' &&
            options.rightButtonText &&
            options.rightButtonUrl
          "
          :href="options.rightButtonUrl"
          class="self-center md:self-end bg-brand-primary !text-white rounded-30 font-bold"
          :style="buttonStyle"
          >{{ options.rightButtonText }}</a
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.image-tile .title,
.image-tile .link a:hover {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}
</style>
