<script lang="ts" setup>
// -----------------------
// props & emits
// -----------------------
import { viewItemList as sendViewItemListEvent } from "~/composables/useGoogleAnalytics";

const props = defineProps<{
  filters?: string;
  showOutOfStock?: boolean;
  title?: string;
}>();

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();

// -----------------------
// reactive properties
// -----------------------
const products = ref([]);
const extendedFilters = computed(() => {
  const parts = [];

  if (!props.showOutOfStock) {
    parts.push("stock > 0");
  }

  if (props.filters && props.filters.length > 0) {
    parts.push(props.filters);
  }

  return parts.join(" AND ");
});

// -----------------------
// vue events
// -----------------------
watch(products, () => {
  sendViewItemListEvent(products.value, { listName: props.title });
});

// -----------------------
// products load logic
// -----------------------
products.value = await useMeilisearch(
  config.public.meilisearchIndexMasterproducts,
  {
    filter: [extendedFilters.value],
  },
);
</script>

<template>
  <CarouselBase
    v-if="products.length > 0"
    v-slot="slotProps"
    :title="title"
    :total-items="products.length"
    :scroll-arrow-size="25"
    :scroll-arrow-spacing="7"
  >
    <ProductListProductCard
      v-for="product in products"
      :key="product.sku"
      :product="product"
      palette=""
      class="mr-[4%] h-auto"
      :style="slotProps.itemsStyle"
    ></ProductListProductCard>
  </CarouselBase>
</template>
