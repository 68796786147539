<script lang="ts" setup>
import { useMainStore } from "~/stores/main";
import type { BackgroundImage } from "~/types/common";

interface BannerConfiguration {
  size?: "S" | "M" | "L";
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  buttonStyle?: string;
  buttonText?: string;
  buttonUrl?: string;
  backgroundImage?: BackgroundImage;
  mobileBackgroundImage?: BackgroundImage;
}

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  options: BannerConfiguration;
}>();

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const { getAssetUrl } = useHelpers();

// -----------------------
// reactive properties
// -----------------------
const container = ref();
const containerWidth = ref<number>(0);
const containerHeight = ref<number>(0);
const baseWidth = ref<number>(1920);
const mobileBaseWidth = ref<number>(390);
const smallBaseHeight = ref<number>(300);
const mobileSmallBaseHeight = ref<number>(150);
const mediumBaseHeight = ref<number>(480);
const mobileMediumBaseHeight = ref<number>(300);
const largeBaseHeight = ref<number>(700);
const mobileLargeBaseHeight = ref<number>(500);

// -----------------------
// computed properties
// -----------------------
const contentContainerStyle = computed(() => {
  const style: Record<string, string> = {};

  if (mainStore.breakpoint === "sm") {
    if (props.options.size === "L") {
      style["padding-top"] = `${getRelativeSize(90)}px`;
      style["padding-bottom"] = `${getRelativeSize(63)}px`;
    } else if (props.options.size === "M") {
      style["padding-left"] = style["padding-right"] =
        `${getRelativeSize(70)}px`;
      style["padding-top"] = `${getRelativeSize(51)}px`;
      style["padding-bottom"] = `${getRelativeSize(29)}px`;
    }
  } else if (props.options.size === "L") {
    style["padding-top"] = `${getRelativeSize(169)}px`;
    style["padding-bottom"] = `${getRelativeSize(50)}px`;
  } else if (props.options.size === "M") {
    style["padding-top"] = `${getRelativeSize(122)}px`;
    style["padding-bottom"] = `${getRelativeSize(52)}px`;
  }

  return style;
});

const backgroundImageVisible = computed(() => {
  return !!(
    props.options.backgroundImage &&
    "url" in props.options.backgroundImage &&
    props.options.backgroundImage.url !== ""
  );
});

const mobileBackgroundImageVisible = computed(() => {
  return !!(
    props.options.mobileBackgroundImage &&
    "url" in props.options.mobileBackgroundImage &&
    props.options.mobileBackgroundImage.url !== ""
  );
});

const backgroundImageUrl = computed(() => {
  if (props.options.backgroundImage && "url" in props.options.backgroundImage) {
    return getAssetUrl(props.options.backgroundImage.url);
  } else {
    return "";
  }
});

const mobileBackgroundImageUrl = computed(() => {
  if (
    props.options.mobileBackgroundImage &&
    "url" in props.options.mobileBackgroundImage
  ) {
    return getAssetUrl(props.options.mobileBackgroundImage.url);
  } else {
    return "";
  }
});

const backgroundImageAspectRatio = computed(() => {
  if (
    props.options.backgroundImage &&
    "width" in props.options.backgroundImage &&
    "height" in props.options.backgroundImage
  ) {
    return `${props.options.backgroundImage.width} / ${props.options.backgroundImage.height}`;
  } else {
    return null;
  }
});

const mobileBackgroundImageAspectRatio = computed(() => {
  if (
    props.options.mobileBackgroundImage &&
    "width" in props.options.mobileBackgroundImage &&
    "height" in props.options.mobileBackgroundImage
  ) {
    return `${props.options.mobileBackgroundImage.width} / ${props.options.mobileBackgroundImage.height}`;
  } else {
    return null;
  }
});

const containerStyle = computed(() => {
  if (mainStore.breakpoint === "sm" && mobileBackgroundImageAspectRatio.value) {
    return {
      "aspect-ratio": mobileBackgroundImageAspectRatio.value,
    };
  } else if (
    mainStore.breakpoint !== "sm" &&
    backgroundImageAspectRatio.value
  ) {
    return {
      "aspect-ratio": backgroundImageAspectRatio.value,
    };
  } else {
    return "";
  }
});

const titleVisible = computed(() => {
  return props.options.title && props.options.title !== "";
});

const titleStyle = computed(() => {
  const style: Record<string, string> = {};

  if (containerWidth.value <= 768) {
    style["font-size"] = `${30 + getRelativeRangeSize(0, 6)}px`;
    style["line-height"] = `${34 + getRelativeRangeSize(0, 6)}px`;
  } else {
    style["font-size"] = `${48 + getRelativeRangeSize(0, 12)}px`;
    style["line-height"] = `${48 + getRelativeRangeSize(0, 12)}px`;
  }

  if (props.options.titleColor) {
    style.color = props.options.titleColor;
  }

  return style;
});

const subtitleVisible = computed(() => {
  if (!props.options.subtitle || props.options.subtitle === "") {
    return false;
  }

  if (props.options.size === "S") {
    return false;
  }

  return true;
});

const subtitleStyle = computed(() => {
  const style: Record<string, string> = {};

  if (containerWidth.value <= 768) {
    style["font-size"] = `${20 + getRelativeRangeSize(0, 4)}px`;
    style["line-height"] = `${28 + getRelativeRangeSize(0, 4)}px`;
  } else {
    style["font-size"] = `${30 + getRelativeRangeSize(0, 6)}px`;
    style["line-height"] = `${36 + getRelativeRangeSize(0, 6)}px`;
  }

  if (props.options.subtitleColor) {
    style.color = props.options.subtitleColor;
  }

  return style;
});

const buttonVisible = computed(() => {
  return (
    props.options.size !== "S" &&
    props.options.buttonText &&
    props.options.buttonText !== "" &&
    props.options.buttonUrl &&
    props.options.buttonUrl !== ""
  );
});

const buttonStyle = computed(() => {
  const style: Record<string, string> = {};
  style.height = `${34 + getRelativeRangeSize(0, 10)}px`;
  style["font-size"] = `${14 + getRelativeRangeSize(0, 2)}px`;

  if (mainStore.breakpoint === "sm") {
    if (props.options.size !== "S") {
      style["margin-top"] = `auto`;
      style["margin-bottom"] = `0`;
    }

    style["padding-left"] = style["padding-right"] = `${getRelativeSize(30)}px`;
  } else if (props.options.size === "L") {
    style["padding-left"] = style["padding-right"] = `${getRelativeSize(60)}px`;
  } else if (props.options.size === "M") {
    style["padding-left"] = style["padding-right"] = `${getRelativeSize(60)}px`;
  }

  return style;
});

// -----------------------
// helper methods
// -----------------------
const getRelativeSize = (basis: number, heightBased: boolean = false) => {
  const left = heightBased ? containerHeight.value : containerWidth.value;
  let right = 1;

  if (mainStore.breakpoint === "sm") {
    if (heightBased) {
      if (props.options.size === "S") {
        right = mobileSmallBaseHeight.value;
      } else if (props.options.size === "M") {
        right = mobileMediumBaseHeight.value;
      } else if (props.options.size === "L") {
        right = mobileLargeBaseHeight.value;
      }
    } else {
      right = mobileBaseWidth.value;
    }
  } else if (heightBased) {
    if (props.options.size === "S") {
      right = smallBaseHeight.value;
    } else if (props.options.size === "M") {
      right = mediumBaseHeight.value;
    } else if (props.options.size === "L") {
      right = largeBaseHeight.value;
    }
  } else {
    right = baseWidth.value;
  }

  return Math.round(left * (basis / right));
};

const getRelativeRangeSize = (min: number, max: number): number => {
  let base;
  let containerWidthBase;
  const diff = max - min;

  if (containerWidth.value <= 768) {
    base = containerWidth.value;
    containerWidthBase = 768;
  } else {
    base = containerWidth.value - 769;
    containerWidthBase = 1920 - 769;
  }

  return Math.min(base / (containerWidthBase / diff), diff);
};

const updateBaseSizes = () => {
  if (container.value) {
    const clientRect = container.value.getBoundingClientRect();

    containerWidth.value = clientRect.width;
    containerHeight.value = clientRect.height;
  }
};

onMounted(() => {
  window.addEventListener("resize", updateBaseSizes);
});
</script>

<template>
  <div ref="container" class="relative" :style="containerStyle">
    <img
      v-if="backgroundImageVisible"
      :src="backgroundImageUrl"
      :alt="options.title ?? ''"
      class="w-full h-auto hidden md:block"
      @load="updateBaseSizes"
    />
    <img
      v-if="mobileBackgroundImageVisible"
      :src="mobileBackgroundImageUrl"
      :alt="options.title ?? ''"
      class="w-full h-auto block md:hidden"
      @load="updateBaseSizes"
    />
    <div
      class="absolute top-0 left-0 w-full h-full flex flex-col md:items-center"
      :class="{
        'justify-center': options.size === 'S',
        'justify-between':
          mainStore.breakpoint !== 'sm' && options.size !== 'S',
      }"
      :style="contentContainerStyle"
    >
      <!-- Title -->
      <h1
        v-if="titleVisible"
        :style="titleStyle"
        class="text-center font-bold font-lora text-white !mt-0 drop-shadow"
        :class="{
          grow:
            mainStore.breakpoint !== 'sm' &&
            !subtitleVisible &&
            options.size !== 'S',
        }"
      >
        {{ options.title }}
      </h1>

      <!-- Subtitle -->
      <h2
        v-if="subtitleVisible"
        :style="subtitleStyle"
        class="font-bold font-lora text-white text-center drop-shadow"
        :class="{ grow: mainStore.breakpoint !== 'sm' }"
      >
        {{ options.subtitle }}
      </h2>

      <!-- Button -->
      <a
        v-if="buttonVisible"
        :href="options.buttonUrl"
        class="flex items-center justify-center self-center md:font-bold rounded-full font-bold"
        :class="{
          'bg-brand-primary !text-brand-surface':
            options.buttonStyle === 'primary',
          'bg-brand-secondary !text-brand-headline':
            options.buttonStyle === 'secondary',
        }"
        :style="buttonStyle"
        >{{ options.buttonText }}</a
      >
    </div>
  </div>
</template>
