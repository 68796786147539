<script lang="ts" setup>
import { useContentElementListStore } from "~/stores/contentElementList";

// -----------------------
// props & emits
// -----------------------
defineProps<{
  filters: Record<string, string>;
}>();
// -----------------------
// composables
// -----------------------
const contentElementListStore = useContentElementListStore();

const replaceAttributeNameIfNeeded = (name: string) => {
  if (name === "Körtúra") return "Túra típusa";
  return name;
};
</script>

<template>
  <div
    v-show="contentElementListStore.filtersLayerVisible"
    class="block md:hidden absolute top-0 left-0 w-full bg-white p-12"
    style="margin-top: 56px; height: calc(100vh - 56px)"
  >
    <div
      class="mb-12 inline-flex items-center cursor-pointer"
      @click="contentElementListStore.filtersLayerVisible = false"
    >
      <i class="text-16 m-icon-cancel mr-[10px]"></i>
      {{ $t("close") }}
    </div>
    <CmsPageContentElementListFacetFilter
      v-for="(attributeName, attribute) in filters"
      :key="attribute"
      :attribute="attribute"
      :attribute-name="attributeName"
      :sort-by="['name:asc']"
      class="mb-5"
      :is-open="true"
    />
  </div>

  <div class="hidden md:block">
    <div v-for="(attributeName, attribute) in filters" :key="attribute">
      <CmsPageContentElementListFacetFilter
        v-if="['a', 'b', 'c', 'd', 'e', 'f', 'i'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="replaceAttributeNameIfNeeded(attributeName)"
        :sort-by="['name:asc']"
        class="mb-5"
        :is-open="true"
      />
      <CmsPageContentElementListRangeFilter
        v-if="['h'].includes(attribute)"
        :attribute="attribute"
        :attribute-name="attributeName"
        data-type="distance"
        :step="1"
        class="mb-5"
        :is-open="true"
      />
    </div>
  </div>
</template>
