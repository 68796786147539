<script lang="ts" setup>
import { AisCurrentRefinements } from "vue-instantsearch/vue3/es";

const getItemLabel = (val: any) => {
  const parts = val.split("|");
  if (parts.length === 2) return parts[1];
  return val;
};
</script>

<template>
  <ais-current-refinements v-slot="{ items, createURL }">
    <span v-for="item in items" :key="item.attribute">
      <span
        v-for="refinement in item.refinements"
        :key="
          [
            refinement.attribute,
            refinement.type,
            refinement.value,
            refinement.operator,
          ].join(':')
        "
      >
        <a
          :href="createURL(refinement)"
          @click.prevent="item.refine(refinement)"
        >
          <span
            class="inline-flex items-center gap-1.5 py-1.5 pl-3 pr-2 rounded-full text-xs font-medium bg-brand-surface mr-1 mb-2 text-black"
          >
            {{ getItemLabel(refinement.label) }}
            <button
              type="button"
              :aria-label="refinement.label"
              class="flex-shrink-0 h-4 w-4 inline-flex items-center justify-center rounded-full text-black hover:bg-secondary00 hover:text-surface00 focus:outline-none focus:bg-secondary00 focus:text-surface00"
            >
              <span class="sr-only">{{ $t("filter_delete") }}</span>
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </span>
        </a>
      </span>
    </span>
  </ais-current-refinements>
</template>
