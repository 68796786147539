<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import ProductCarousel from "~/components/Carousel/ProductCarousel.vue";
import ImageCarousel from "~/components/Carousel/ImageCarousel.vue";
const props = defineProps<{ block: Record<string, any> }>();

// -----------------------
// composables
// -----------------------
const { getAssetUrl } = useHelpers();

// -----------------------
// reactive properties
// -----------------------
const customProductListFilters = ref<string | null>(null);
const customProductListHiddenFacets = computed(() => {
  const parsedFilters = JSON.parse(props.block.attributes["data-filters"]);
  const hiddenFacets = [];

  for (const andFilter of parsedFilters) {
    for (const orFilter of andFilter) {
      if ("propertyCode" in orFilter) {
        hiddenFacets.push(
          orFilter.propertyCode.replace(".", "_") + "_property",
        );
      }
    }
  }

  return hiddenFacets;
});

const componentOptions = computed(() => {
  return JSON.parse(props.block.attributes["data-options"]);
});

const componentStyle = computed(() => {
  if (
    "block" in props &&
    "attributes" in props.block &&
    "data-style" in props.block.attributes
  ) {
    return props.block.attributes["data-style"];
  }

  return null;
});

const youtubeUrl = computed(() => {
  return "url" in componentOptions.value ? componentOptions.value.url : "";
});

// -----------------------
// helper methods
// -----------------------
const { getYoutubeVideoId } = useHelpers();
const getFilters = async () => {
  const parsedFilters = JSON.parse(props.block.attributes["data-filters"]);
  const andFilterParts = [];

  for (const andFilter of parsedFilters) {
    const orFilterParts = [];

    for (const orFilter of andFilter) {
      // Master product SKU based filter/condition type
      if ("filterType" in orFilter && orFilter.filterType === "sku") {
        orFilterParts.push(
          orFilter.skuList
            .split(",")
            .map((sku) => `sku = ${sku}`)
            .join(" OR "),
        );
      }
      // Master product in_action filter type
      if (orFilter.filterType === "in_action") {
        orFilterParts.push("c.in_action = True"); // fixme: "c" is hardcoded here, it should be configurable
      }
      // Entity-property based filter/condition type
      else if ("taxonNames" in orFilter && "propertyName" in orFilter) {
        const attributeName =
          orFilter.propertyCode.replace(".", "_") + "_property";

        if (orFilter.taxonomyType === "hierarchical") {
          for (const taxonId of orFilter.taxonIds) {
            const taxonData = await $fetch(`/api/meilisearch/taxon`, {
              params: { id: taxonId },
            });
            // console.log("taxonData", taxonData);
            if (taxonData?.calculated_vis_path) {
              orFilterParts.push(
                `${attributeName}.lvl${taxonData.calculated_vis_path.level} = '${taxonData.calculated_vis_path.path}'`,
              );
            }
          }
        } else if (orFilter.taxonNames && Array.isArray(orFilter.taxonNames)) {
          for (const taxonName of orFilter.taxonNames) {
            orFilterParts.push(`${attributeName} = '${taxonName}'`);
          }
        }
      }
    }

    if (orFilterParts.length > 0) {
      andFilterParts.push(`(${orFilterParts.join(" OR ")})`);
    }
  }

  if (andFilterParts.length > 0) {
    return andFilterParts.join(" AND ");
  }

  return "";
};

const handleButtonClick = () => {
  let url = componentOptions.value.url;

  if (/^#/.test(url)) {
    location.hash = url;

    return;
  }

  let external = true;

  if (/^\//.test(url)) {
    external = false;
  } else {
    const urlObj = new URL(url);

    if (
      urlObj.protocol === location.protocol &&
      urlObj.host === location.host
    ) {
      external = false;
      // Must remove the protocol + host prefix, otherwise Nuxt throws error
      url = urlObj.pathname + urlObj.search + urlObj.hash;
    }
  }

  navigateTo(url, { external });
};

// -----------------------
// vue events
// -----------------------
onMounted(async () => {
  if (props.block.type === "custom-product-list") {
    try {
      customProductListFilters.value = await getFilters();
    } catch (error) {
      const nuxtError = useError();
      nuxtError.value = {
        statusCode: error?.response?.status || 500,
        statusMessage: error?.response?.statusText || "An error occurred",
      };
    }
  }
  // console.log(props.block)
});
</script>
<template>
  <template v-if="props.block.type === 'textnode'">
    {{ props.block.content }}
  </template>
  <template v-else-if="props.block.type === 'link'">
    <a
      v-if="Array.isArray(props.block.components)"
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      :href="props.block.attributes.href"
    >
      <template v-for="(item, index) in props.block.components" :key="index">
        <CmsPageContentBlock
          v-bind="{
            block: item,
          }"
        />
      </template>
    </a>
  </template>
  <template v-else-if="props.block.type === 'text'">
    <CmsPageTextType
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      :block="props.block"
    />
  </template>
  <template
    v-else-if="
      props.block.type === 'image' || props.block.type === 'custom-image'
    "
  >
    <div
      class="px-16"
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
    >
      <template v-if="componentOptions.mobileImage?.url">
        <img
          class="hidden md:inline"
          :src="getAssetUrl(componentOptions.image.url)"
          :alt="componentOptions.alt ?? ''"
        />
        <img
          class="inline md:hidden"
          :src="getAssetUrl(componentOptions.mobileImage.url)"
          :alt="componentOptions.alt ?? ''"
        />
      </template>
      <template v-else>
        <img
          :src="getAssetUrl(componentOptions.image.path)"
          :alt="componentOptions.alt ?? ''"
        />
      </template>
    </div>
  </template>
  <template v-else-if="props.block.type === 'audio'">
    <audio
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      controls
      :src="getAssetUrl(props.block.components[0].attributes.src)"
    />
  </template>
  <template v-else-if="props.block.type === 'video'">
    <video
      class="p-16 max-w-full"
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      controls
      :src="getAssetUrl(props.block.src)"
    />
  </template>
  <template
    v-else-if="
      props.block.type === 'custom-product-list' &&
      props.block.attributes['data-type'] === 'product_list' &&
      customProductListFilters !== null
    "
  >
    <ProductList
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      :filters="customProductListFilters"
      :hide-facets="customProductListHiddenFacets"
      :title="props.block.attributes['data-title'] ?? ''"
      :before-content="props.block.attributes['data-before-content'] ?? ''"
      :after-content="props.block.attributes['data-after-content'] ?? ''"
    />
  </template>
  <template
    v-else-if="
      props.block.type === 'custom-product-list' &&
      props.block.attributes['data-type'] === 'carousel' &&
      customProductListFilters !== null
    "
  >
    <ProductCarousel
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      :items-number="4"
      :filters="customProductListFilters"
      :title="props.block.attributes['data-title'] ?? ''"
    />
  </template>
  <template v-else-if="props.block.type === 'banner'">
    <BannerBase
      :style="componentStyle"
      :class="props.block.classes?.join(' ')"
      :options="componentOptions"
    ></BannerBase>
  </template>
  <template v-else-if="props.block.type === 'tiles'">
    <TilesBase
      :style="componentStyle"
      :class="props.block.classes?.join(' ')"
      :options="componentOptions"
    ></TilesBase>
  </template>
  <template v-else-if="props.block.type === 'carousel'">
    <ImageCarousel
      :style="componentStyle"
      :class="props.block.classes?.join(' ')"
      :options="componentOptions"
    ></ImageCarousel>
  </template>
  <template v-else-if="props.block.type === 'content-element-list'">
    <CmsPageContentElementList
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      :content-type-id="props.block.attributes['data-content-type-id']"
      :before-content="props.block.attributes['data-before-content']"
      :after-content="props.block.attributes['data-after-content']"
    ></CmsPageContentElementList>
  </template>
  <template v-else-if="props.block.type === 'raw-html'">
    <!-- eslint-disable vue/no-v-html -->
    <div
      :style="componentStyle"
      :class="props.block.classes?.join(' ')"
      v-html="props.block.attributes['raw-html-code']"
    ></div>
    <!--eslint-enable-->
  </template>
  <template v-else-if="props.block.type === 'youtube'">
    <iframe
      class="p-16 max-w-full"
      :class="props.block.classes?.join(' ')"
      :style="componentStyle"
      width="560"
      height="315"
      :src="'//www.youtube.com/embed/' + getYoutubeVideoId(youtubeUrl)"
      title="Youtube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </template>
  <template v-else-if="props.block.type === 'button'">
    <BaseButton
      :style="componentStyle"
      :class="props.block.classes?.join(' ')"
      :type="componentOptions.type"
      :title="componentOptions.title ?? ''"
      @click="handleButtonClick"
    >
      {{ componentOptions.text }}
    </BaseButton>
  </template>
  <div
    v-else-if="props.block.type === 'default'"
    :style="componentStyle"
    :class="props.block.classes?.join(' ')"
  >
    <template v-for="(item, index) in props.block.components" :key="index">
      <template v-if="item.tagName">
        <BaseTag
          :tag-name="item.tagName"
          :class="item.classes?.join(' ')"
          :attributes="item.attributes"
        >
          <CmsPageContentBlock
            v-bind="{
              block: item,
            }"
          />
        </BaseTag>
      </template>
      <template v-else>
        <CmsPageContentBlock
          v-bind="{
            block: item,
          }"
        />
      </template>
    </template>
  </div>
  <template
    v-else-if="
      props.block.type !== 'default' && Array.isArray(props.block.components)
    "
  >
    <template v-for="(item, index) in props.block.components" :key="index">
      <template v-if="item.tagName">
        <component :is="item.tagName">
          <CmsPageContentBlock
            v-bind="{
              block: item,
            }"
          />
        </component>
      </template>
      <template v-else>
        <CmsPageContentBlock
          v-bind="{
            block: item,
          }"
        />
      </template>
    </template>
  </template>
</template>
