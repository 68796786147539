<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isCurrent?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    label?: string;
  }>(),
  {
    isCurrent: false,
    isFirst: false,
    isLast: false,
    label: "",
  },
);

const emit = defineEmits<{
  (e: "page-click"): void;
}>();

const aClasses = () => {
  const classes: string[] = [
    "flex",
    "items-center",
    "px-3",
    "h-8",
    "leading-tight",
    "noSelect",
  ];

  if (props.isCurrent) {
    classes.push("bg-black", "z-10", "!text-white");
  } else {
    classes.push("!text-black", "hover:bg-brand-primary", "hover:!text-white");
  }

  return classes.join(" ");
};

const handlePageClick = (e) => {
  emit("page-click");
  useScrollElementToView(e.target.closest(".content-element-list").id);
};
</script>

<template>
  <div>
    <a
      href="#"
      :class="aClasses()"
      :aria-label="label"
      @click.prevent="handlePageClick"
      ><slot name="default"
    /></a>
  </div>
</template>
