import { defineStore } from "pinia";
import { ContentElementListStore } from "~/types/common";

export const useContentElementListStore = defineStore(
  "contentElementListStore",
  {
    state: (): ContentElementListStore => ({
      filtersLayerVisible: false,
    }),
  },
);
