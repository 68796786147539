<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    tagName: string;
    class: string;
    attributes?: Record<string, string> | null;
  }>(),
  {
    tagName: "",
    class: "",
    attributes: null,
  },
);
</script>

<template>
  <template v-if="props.tagName">
    <component
      :is="props.tagName"
      :id="props.attributes?.id"
      :title="props.attributes?.title"
      :class="props.class"
    >
      <slot></slot>
    </component>
  </template>
</template>
