<script setup lang="ts">
const props = defineProps<{ block: Record<string, any> }>();

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  // console.log(props.block);
});
</script>
<template>
  <div :class="props.block.classes?.join(' ')">
    <template v-if="props.block.content">
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="props.block.content" />
      <!--eslint-enable-->
    </template>
    <template v-else>
      <template v-for="(item, index) in props.block.components" :key="index">
        <template v-if="item.tagName">
          <BaseTag
            :tag-name="item.tagName"
            :class="item.classes?.join(' ')"
            :attributes="item.attributes"
          >
            <CmsPageContentBlock
              v-bind="{
                block: item,
              }"
            />
          </BaseTag>
        </template>
        <template v-else>
          <CmsPageContentBlock
            v-bind="{
              block: item,
            }"
          />
        </template>
      </template>
    </template>
  </div>
</template>
